
@font-face {
    font-family: "AppleSDGothicNeo";
    font-weight: 100;
	src: url("../src/fonts/AppleSDGothicNeoT.woff2") format("font-woff2");
}

@font-face {
    font-family: "AppleSDGothicNeo";
    font-weight: 200;
	src: url("../src/fonts/AppleSDGothicNeoUL.woff2") format("font-woff2");
}

@font-face {
    font-family: "AppleSDGothicNeo";
    font-weight: 300;
	src: url("../src/fonts/AppleSDGothicNeoL.woff2") format("font-woff2");
}

@font-face {
    font-family: "AppleSDGothicNeo";
    font-weight: 400;
	src: url("../src/fonts/AppleSDGothicNeoR.woff2") format("font-woff2");
}

@font-face {
    font-family: "AppleSDGothicNeo";
    font-weight: 500;
	src: url("../src/fonts/AppleSDGothicNeoM.woff2") format("font-woff2");
}

@font-face {
    font-family: "AppleSDGothicNeo";
    font-weight: 600;
	src: url("../src/fonts/AppleSDGothicNeoSB.woff2") format("font-woff2");
}

@font-face {
    font-family: "AppleSDGothicNeo";
    font-weight: 700;
	src: url("../src/fonts/AppleSDGothicNeoB.woff2") format("font-woff2");
}

@font-face {
    font-family: "AppleSDGothicNeo";
    font-weight: 800;
	src: url("../src/fonts/AppleSDGothicNeoEB.woff2") format("font-woff2");
}

@font-face {
    font-family: "AppleSDGothicNeo";
    font-weight: 900;
    src: url("../src/fonts/AppleSDGothicNeoH.woff2") format("font-woff2");
}

@font-face {
    font-family: "Raleway";
    font-weight: 600;
    src: url("../src/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: "RalewayB";
    font-weight: 700;
    src: url("../src/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

body {
	font-family: 'AppleSDGothicNeo', sans-serif;
}

.raleway {
	font-family: 'Raleway';
}

.ralewayB {
	font-family: 'RalewayB';
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}